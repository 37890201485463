
import {
  AnalyticsTypes,
  DashboardAnalyticsState,
  DashboardFilter,
} from "@/store/modules/analytics/analytics.types";
import { JsUserProfile } from "@/store/modules/auth/auth.types";
import { FilterType, FilterValue } from "@/types/types";
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";

const Auth = namespace("Auth");
const Analytics = namespace("Analytics");

@Component({})
export default class MyTicketStats extends Vue {
  @Auth.State("userProfile")
  public userProfile?: JsUserProfile;

  @Analytics.State(AnalyticsTypes.DASHBOARD_ANALYTICS)
  public dashboardAnalytics!: DashboardAnalyticsState;

  @Analytics.Getter(AnalyticsTypes.CURRENT_DASHBOARD_FILTER)
  public currentDashboardFilter!: FilterType;

  @Analytics.Action(AnalyticsTypes.LOAD_DASHBOARD_ANALYTICS)
  public loadDashboardStats!: (filter: DashboardFilter) => void;

  public filterValue: FilterValue = {};

  filterChange(value: FilterValue): void {
    this.filterValue = value;

    this.loadDashboardStats({
      filterBy: value.filterValue?.value,
      force: value.forced,
    });
  }

  get period(): string {
    return this.currentDashboardFilter.value.toLocaleLowerCase();
  }

  mounted(): void {
    this.filterValue.filterValue = this.currentDashboardFilter;
    this.filterChange(this.filterValue);
  }
}
